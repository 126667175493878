<template>
  <v-form v-model="valid" @submit.prevent="sendCode">
    <label class="label_title mb-5">{{ $t("auth.verifyphone") }}</label>
    <v-text-field
      v-model="phoneNumber"
      label="+## ### ### ###"
      filled
      outlined
      color="grey lighten-1"
      required
      :rules="phoneNumberRules"
      v-mask="'+## ### ### ###'"
      hide-details
      :placeholder="'+## ### ### ###'"
    ></v-text-field>
    <v-btn
      type="submit"
      color="blue-grey"
      block
      class="btn_login mb-5 text-uppercase"
      :loading="loading"
      :disabled="!valid"
      style="margin-top:15px;"
      id="verify-phone-number-btn"
    >
      {{ $t("auth.sendcode") }}
    </v-btn>
  </v-form>
</template>

<script>
import {
  sendConfirmationPhoneNumber,
  createRecapthaVerifier,
} from "@/plugins/firebase";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      phoneNumberRules: [
        (v) => v.length >= 15 || "Phone must be 11 characters",
      ],
      phoneNumber: "",
    };
  },
  mounted() {
    createRecapthaVerifier();
  },
  methods: {
    async sendCode() {
      this.loading = true;
      // var exist = await this.$httpClient.post(
      //   `/user/checkphone`,
      //   {},
      //   { phone: this.phoneNumber }
      // );
      // if (exist) {
      //   this.loading = false;
      //   return this.showError(
      //     "Phone number already in-used! Please try another phone number."
      //   );
      // }
      try {
        var confirmationResult = await sendConfirmationPhoneNumber(
          this.phoneNumber
        );
        this.$emit("success", confirmationResult);
      } catch (err) {
        this.showError("Send Code Failed");
      }
      this.loading = false;
    },
  },
};
</script>
